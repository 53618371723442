import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

import RequestError from './RequestError';
import HealthSection from './HealthSection';
import SecuritySection from './SecuritySection';
import PerformanceSection from './PerformanceSection';
import UXSection from './UXSection';

import './styles.scss';

const ApiDocumentation = () => {
  return (
    <Layout>
      <PageHero title="API Documentation" subtitle="This page provides API documentation for tools scan results" />
      <Section>
        <div className="col-12 d-block mb_20 api-doc" style={{maxWidth: '1200px', width: '100%'}}>
          <p>To get all your properties use this get API.</p>
          <h5>HTTP Request</h5>
          <pre className="pre">
            <p>
              <span className="btn-pre">GET</span>{' '}
              https://api.hexometer.com/v2/app/services/v1/properties?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit{' '}
                    <a href="https://dash.hexometer.com" no-index>
                      {' '}
                      hexometer{' '}
                    </a>
                    select desired property then go to settings and copy your API key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Response Body</h5>
          <p>if request is successful you will get</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"properties": [</p>
            <p className="mgl-50"> Propertiy</p>
            <p className="mgl-25">]</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">Propertiy []</td>
                <td>
                  <p className="bold-text">
                    object(<span className="normal-text">Propertiy</span>)
                  </p>
                  <p className="normal-text">Propertiy represents a propertiy object</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Propertiy</h5>
          <p>Propertiy object structure</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>Propertiy = {'{'}</p>
            <p className="mgl-25">"propertiy_id": Number,</p>
            <p className="mgl-25">"propertiy_address": String</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">propertiy_id</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">propertiy id</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">propertiy_address</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">URL address</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"properties": {'['}</p>

            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"propertiy_id": 8,</p>
            <p className="mgl-75">"propertiy_address": "test.io",</p>
            <p className="mgl-50">{'},'}</p>
            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"propertiy_id": 9,</p>
            <p className="mgl-75">"propertiy_address": "https://example.com",</p>
            <p className="mgl-50">{'}'}</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>
          <RequestError />
          <p>To get your property's links HTTP statuses use this get API.</p>
          <h5>HTTP Request</h5>
          <pre className="pre">
            <p>
              <span className="btn-pre">POST</span>{' '}
              https://api.hexometer.com/v2/app/services/v1/health_links/statuses?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit{' '}
                    <a href="https://dash.hexometer.com" no-index>
                      {' '}
                      hexometer{' '}
                    </a>
                    select desired property then go to settings and copy your API key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Request Body</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">property_id</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">you should provide property id</p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"property_id": 25</p>
            <p>{'}'}</p>
          </pre>

          <h5>Response Body</h5>
          <p>if request is successful you will get</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"links_statuses": [</p>
            <p className="mgl-50"> Number</p>
            <p className="mgl-25">]</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">Number []</td>
                <td>
                  <p className="bold-text">
                    number(<span className="normal-text">Number</span>)
                  </p>
                  <p className="normal-text">Number represents an interger</p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"links_statuses": {'['}</p>
            <p className="mgl-50">200,</p>
            <p className="mgl-50">999</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>
          <RequestError />
          <p>To get website links use this get API.</p>
          <h5>HTTP Request</h5>
          <pre className="pre">
            <p>
              <span className="btn-pre">POST</span>{' '}
              https://api.hexometer.com/v2/app/services/v1/health_links?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit{' '}
                    <a href="https://dash.hexometer.com" no-index>
                      {' '}
                      hexometer{' '}
                    </a>
                    select desired property then go to settings and copy your API key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Request Body</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">property_id</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">you should provide property id</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">status</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">you should provide HTTP status number</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"property_id": 25,</p>
            <p className="mgl-25">"status": 200</p>
            <p>{'}'}</p>
          </pre>

          <h5>Response Body</h5>
          <p>if request is successful you will get</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"links": [</p>
            <p className="mgl-50"> Link</p>
            <p className="mgl-25">]</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">if the error returns false, it means that no error has occurred</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">Link []</td>
                <td>
                  <p className="bold-text">
                    object(<span className="normal-text">Link</span>)
                  </p>
                  <p className="normal-text">Link represents a link object</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Link</h5>
          <p>Link object structure</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>Link = {'{'}</p>

            <p className="mgl-25">"metaTags": {'{'}</p>
            <p className="mgl-50">"open_graph": Array,</p>
            <p className="mgl-50">"twitter_card": Array,</p>
            <p className="mgl-50">"description": null | String</p>
            <p className="mgl-25">{'},'}</p>

            <p className="mgl-25">"error": null | String,</p>
            <p className="mgl-25">"url": null | String,</p>
            <p className="mgl-25">"fileType": null | String,</p>

            <p className="mgl-25">"redirectUrl": null | String,</p>

            <p className="mgl-25">"parent_url": null | String,</p>
            <p className="mgl-25">"status": Number,</p>
            <p className="mgl-25">"contentType": null | String,</p>
            <p className="mgl-25">"contentLength": Number,</p>
            <p className="mgl-25">"internal": Boolean,</p>
            <p className="mgl-25">"nofollow": Boolean,</p>
            <p className="mgl-25">"createdAt": String,</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">metaTags</td>
                <td>
                  <p className="bold-text">object</p>
                  <p className="normal-text">consists of open graph, twitter card arrays and description field</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">null | string</p>
                  <p className="normal-text">null or error text</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">URL</td>
                <td>
                  <p className="bold-text">null | string</p>
                  <p className="normal-text">link URL</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">filetype</td>
                <td>
                  <p className="bold-text">null | string</p>
                  <p className="normal-text">file type</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">redirectUrl</td>
                <td>
                  <p className="bold-text">null |string</p>
                  <p className="normal-text">redirect URL</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">parent_url</td>
                <td>
                  <p className="bold-text"> null | string</p>
                  <p className="normal-text">website base URL</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">status</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">status code</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">contentType</td>
                <td>
                  <p className="bold-text">null | string</p>
                  <p className="normal-text">content type</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">contentLength</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">content length</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">internal</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">internal link</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">nofollow</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">nofollow link</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">createdAt</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">link creation date</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Example 1</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"links": {'['}</p>

            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"metaTags": {'{'}</p>
            <p className="mgl-125">"open_graph": [],</p>
            <p className="mgl-125">"twitter_card": [],</p>
            <p className="mgl-125">"description": null,</p>
            <p className="mgl-75">{'},'}</p>

            <p className="mgl-75">"error": null,</p>
            <p className="mgl-75">"url": "https://www.example.com/company",</p>
            <p className="mgl-75">"fileType": "html",</p>
            <p className="mgl-75">"redirectUrl": "https://www.example.com/company",</p>

            <p className="mgl-75">"parent_url": "https://example.io",</p>
            <p className="mgl-75">"status": 999,</p>
            <p className="mgl-75">"contentType": "text/html",</p>
            <p className="mgl-75">"contentLength": 1529,</p>
            <p className="mgl-75">"internal": false,</p>
            <p className="mgl-75">"nofollow": false,</p>
            <p className="mgl-75">"createdAt": "2021-07-02T13:51:08.023Z"</p>
            <p className="mgl-50">{'}'}</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>

          <h5>Example 2</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"error": false,</p>
            <p className="mgl-25">"links": {'['}</p>

            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"metaTags": {'{'}</p>
            <p className="mgl-125">"open_graph": [],</p>
            <p className="mgl-125">"twitter_card": [</p>
            <p className="mgl-150">{'{'}</p>
            <p className="mgl-175">"name": "twitter:image",</p>
            <p className="mgl-175">"isExists": true",</p>
            <p className="mgl-175">"content": "https://example.io/example.jpg"</p>
            <p className="mgl-150">{'},'}</p>
            <p className="mgl-150">{'{'}</p>
            <p className="mgl-175">"name": "twitter:title",</p>
            <p className="mgl-175">"isExists": true",</p>
            <p className="mgl-175">"content": "Available Vacancies at company"</p>
            <p className="mgl-150">{'}'}</p>

            <p className="mgl-125">{']'}</p>
            <p className="mgl-125">
              "description": We are looking for dedicated and inovative people to become a part of growing team
            </p>
            <p className="mgl-75">{'},'}</p>

            <p className="mgl-75">"error": null,</p>
            <p className="mgl-75">"url": "https://www.example.com/company",</p>
            <p className="mgl-75">"fileType": "html",</p>
            <p className="mgl-75">"redirectUrl": "https://www.example.com/company",</p>

            <p className="mgl-75">"parent_url": "https://example.io",</p>
            <p className="mgl-75">"status": 999,</p>
            <p className="mgl-75">"contentType": "text/html",</p>
            <p className="mgl-75">"contentLength": 1529,</p>
            <p className="mgl-75">"internal": false,</p>
            <p className="mgl-75">"nofollow": false,</p>
            <p className="mgl-75">"createdAt": "2021-07-02T13:51:08.023Z"</p>
            <p className="mgl-50">{'}'}</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>
          <RequestError />
          <p>To get detected errors use this get API.</p>
          <h5>HTTP Request for Domain &amp; DNS tool (Security)</h5>
          <pre className="pre">
            <p>
              <span className="btn-pre">POST</span>{' '}
              https://api.hexometer.com/v2/app/services/v1/detected_errors?key=&#123;YOUR_API_KEY&#125;
            </p>
          </pre>
          <h5>Parameters</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">YOUR_API_KEY</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    {' '}
                    to get YOUR_API_KEY visit{' '}
                    <a href="https://dash.hexometer.com" no-index>
                      {' '}
                      hexometer{' '}
                    </a>
                    select desired property then go to settings and copy your API key
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Request Body</h5>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">tool_name</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">
                    you should provide the specific tool name to get the corresponding error. In this case
                    "Security_Domain_&_DNS"
                  </p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">property_id</td>
                <td>
                  <p className="bold-text">number</p>
                  <p className="normal-text">you should provide property id</p>
                </td>
              </tr>
            </tbody>
          </table>

          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"tool_name": "Security_Domain_&_DNS",</p>
            <p className="mgl-25">"property_id": 25</p>
            <p>{'}'}</p>
          </pre>

          <h5>Response Body</h5>
          <p>if request is successful you will get</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"tool_logs": [</p>
            <p className="mgl-50"> DomainWhoisTool,</p>
            <p className="mgl-50"> IpExtractionTool</p>
            <p className="mgl-25">]</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">DomainWhoisTool</td>
                <td>
                  <p className="bold-text">
                    object(<span className="normal-text">DomainWhoisTool</span>)
                  </p>
                  <p className="normal-text">DomainWhoisTool represents a domainWhoisTool object</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">IpExtractionTool</td>
                <td>
                  <p className="bold-text">
                    object(<span className="normal-text">IpExtractionTool</span>)
                  </p>
                  <p className="normal-text">IpExtractionTool represents an ipExtractionTool object</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Domain Whois Tool</h5>
          <p>DomainWhoisTool object structure</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>DomainWhoisTool = {'{'}</p>
            <p className="mgl-25">"domainWhoisTool": {'{'}</p>
            <p className="mgl-75">"error": null | Object,</p>
            <p className="mgl-75">"url": String,</p>
            <p className="mgl-75">"domain": String,</p>
            <p className="mgl-75">"status": String,</p>
            <p className="mgl-75">"exists": Boolean,</p>
            <p className="mgl-75">"expiresAt": String,</p>
            <p className="mgl-75">"expirationDays": Boolean,</p>
            <p className="mgl-75">"lastModified": String,</p>
            <p className="mgl-75">"registeredAt": String,</p>
            <p className="mgl-75">"fullText": String,</p>
            <p className="mgl-75">"dnsData": String</p>
            <p className="mgl-25">{'}'}</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">null | object</p>
                  <p className="normal-text">
                    if an error exists an object is returned with error code(string) and message(string)
                  </p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">url</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website url</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">domain</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">domain name</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">status</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">domain status</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">exists</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text">true if domain exists</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">expiresAt</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">domain expire date</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">expirationDays</td>
                <td>
                  <p className="bold-text"> string</p>
                  <p className="normal-text">days till expire</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">lastModified</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">last modified date</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">registeredAt</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">registered date</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">fullText</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">additional info (JSON)</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>IP Extraction Tool</h5>
          <p>IpExtractionTool object structure</p>
          <div className="json-title-block">
            <span>JSON representation</span>
          </div>
          <pre className="pre">
            <p>IpExtractionTool = {'{'}</p>
            <p className="mgl-25">"ipExtractionTool": {'{'}</p>
            <p className="mgl-50">"error": null | Object,</p>

            <p className="mgl-50">"resolveInfo": [</p>
            <p className="mgl-75">{'{'}</p>

            <p className="mgl-100">"ip": String,</p>
            <p className="mgl-100">"address": String,</p>
            <p className="mgl-100">"country": Boolean,</p>
            <p className="mgl-100">"createdAt": String,</p>
            <p className="mgl-100">"lastModified": String,</p>
            <p className="mgl-100">"hostnames": Array,</p>
            <p className="mgl-100">"ipRange": String,</p>
            <p className="mgl-100">"company": String,</p>
            <p className="mgl-100">"ipInBlackList": Boolean,</p>
            <p className="mgl-100">"ipBlackList": Array</p>

            <p className="mgl-75">{'}'}</p>
            <p className="mgl-50">{']'}</p>

            <p className="mgl-25">{'}'}</p>
            <p>{'}'}</p>
          </pre>
          <table>
            <thead>
              <tr>
                <th>Fields</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-fill">error</td>
                <td>
                  <p className="bold-text">null | object</p>
                  <p className="normal-text">
                    if an error exists an object is returned with error code(string) and message(string)
                  </p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">ip</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website ip</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">address</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website address</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">country</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website country</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">createdAt</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website creation date</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">lastModified</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">website last modified date</p>
                </td>
              </tr>

              <tr>
                <td className="td-fill">hostnames</td>
                <td>
                  <p className="bold-text">array</p>
                  <p className="normal-text ">host name list</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">ipRange</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text ">your ip range</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">company</td>
                <td>
                  <p className="bold-text">string</p>
                  <p className="normal-text">company name</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">ipInBlackList</td>
                <td>
                  <p className="bold-text">boolean</p>
                  <p className="normal-text ">describe</p>
                </td>
              </tr>
              <tr>
                <td className="td-fill">ipBlackList</td>
                <td>
                  <p className="bold-text">array</p>
                  <p className="normal-text ">list of IP black lists</p>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Example</h5>
          <pre className="pre">
            <p>{'{'}</p>
            <p className="mgl-25">"tool_logs": {'['}</p>
            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"domainWhoisTool": {'{'}</p>
            <p className="mgl-125">"error": null,</p>
            <p className="mgl-125">"url": "example.io",</p>
            <p className="mgl-125">"domain": "example.io",</p>
            <p className="mgl-125">"status": "Registered",</p>
            <p className="mgl-125">"exists": true,</p>
            <p className="mgl-125">"expiresAt": "2023-01-19T06:23:59.000Z",</p>
            <p className="mgl-125">"expirationDays": "561",</p>
            <p className="mgl-125">"lastModified": "2021-02-07T10:33:30.000Z",</p>
            <p className="mgl-125">"registeredAt": "2019-01-19T06:23:59.000Z",</p>
            <p className="mgl-125">"fullText": "example text",</p>
            <p className="mgl-125">"dnsData": "example text"</p>
            <p className="mgl-75">{'}'}</p>
            <p className="mgl-50">{'},'}</p>
            <p className="mgl-50">{'{'}</p>
            <p className="mgl-75">"ipExtractionTool": {'{'}</p>

            <p className="mgl-100">"error": null,</p>
            <p className="mgl-100">"resolveInfo": [</p>
            <p className="mgl-125">{'{'}</p>
            <p className="mgl-125">"ip": "100.21.84.99",</p>
            <p className="mgl-125">"address": "see http://www.example.org.",</p>
            <p className="mgl-125">"country": "EU",</p>
            <p className="mgl-125">"createdAt": "2019-02-04T15:28:58Z",</p>
            <p className="mgl-125">"lastModified": "2019-02-04T15:28:58Z,</p>
            <p className="mgl-125">"hostnames": [],</p>
            <p className="mgl-125">"ipRange": "100.252.92.0 - 104.37.31.255",</p>
            <p className="mgl-125">"company": "Internet Assigned Numbers Authority",</p>
            <p className="mgl-125">"ipInBlackList": false,</p>
            <p className="mgl-125">"ipBlackList": []</p>
            <p className="mgl-125">{'},'}</p>
            <p className="mgl-125">{'{'}</p>
            <p className="mgl-125">"ip": "100.21.84.99",</p>
            <p className="mgl-125">"address": "see http://www.example.org.",</p>
            <p className="mgl-125">"country": "US",</p>
            <p className="mgl-125">"createdAt": "2018-02-04T15:28:58Z",</p>
            <p className="mgl-125">"lastModified": "2019-02-04T15:28:58Z,</p>
            <p className="mgl-125">"hostnames": [],</p>
            <p className="mgl-125">"ipRange": "100.252.92.0 - 104.37.31.255",</p>
            <p className="mgl-125">"company": "Internet Assigned Numbers Authority",</p>
            <p className="mgl-125">"ipInBlackList": false,</p>
            <p className="mgl-125">"ipBlackList": []</p>
            <p className="mgl-125">{'}'}</p>
            <p className="mgl-100">{']'}</p>
            <p className="mgl-75">{'}'}</p>
            <p className="mgl-50">{'}'}</p>
            <p className="mgl-25">{']'}</p>
            <p>{'}'}</p>
          </pre>
          <RequestError />
          <HealthSection />
          <SecuritySection />
          <PerformanceSection />
          <UXSection />
        </div>
      </Section>
    </Layout>
  );
};

export default ApiDocumentation;
